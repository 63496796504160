import React, { Component } from 'react'

import Container from '../container'

import "../../styles/resources.scss";

export class Header extends Component {
  render() {
    return (
      <div className="resources__header">
          <Container>
              <div className="resources__header__content">
                  <h1>Resources</h1>
                  <p>Ready-to-use templates on design <br /> process documentation, product overview and more.</p>
              </div>
          </Container>
      </div>
    )
  }
}

export default Header