import React, { Component } from "react";
import Container from "../container";

import productbrief from "../../images/product-brief.gif";
import designcontract from "../../images/design-contract.gif";
import comingsoon from "../../images/comingsoon.gif";

const list = [
  {
    icon: `${productbrief}`,
    type: "Notion",
    name: "Product Brief",
    description:
      "This template puts your facts,assumption, and other relevant information regarding design projects in writing.",
    url: "#",
  },
  {
    icon: `${designcontract}`,
    type: "Google Doc",
    name: "Design Contract Template",
    description:
      "This serves as a formal contract between a Designer and client to set expectations and condition for services provided.",
    url: "#",
  },
  {
    icon: `${comingsoon}`,
    type: "Notion",
    name: "For every Junior Designer",
    description: "Coming soon...",
    url: "#",
  },
  {
    icon: `${comingsoon}`,
    type: "Notion",
    name: "Writing a Case Study",
    description: "Coming soon...",
    url: "#",
  },
];

export class Details extends Component {
  render() {
    return (
      <section className="resources__details">
        <Container>
          <div className="resources__details__content">
            {list.map((item) => (
              <div className="resources__details__item">
                <div className="resources__details__item__image">
                  <img src={item.icon} alt={item.name} />
                </div>
                <div className="resources__details__item__content">
                  <p className="different">{item.type}</p>
                  <h4>{item.name}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    );
  }
}

export default Details;
