import React, { Component } from 'react'
import Footer from '../components/footer'
import Details from '../components/resources/details'
import Header from '../components/resources/header'

import "../styles/resources.scss";

export class resources extends Component {
  render() {
    return (
      <main className="grey">
        <title>Resources</title>
        <Header />
        <Details />
        <Footer className="dark" />
      </main>
    )
  }
}

export default resources